import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import "./articleProducts.css" // Import external CSS file

const ArticleProducts = ({ articleKey }) => {
  const data = useStaticQuery(graphql`
    query {
      allMysqlArticleProducts {
        nodes {
          article_key
          product_id
          reason
          pros
          cons
        }
      }
      allMongodbStoreProducts {
        edges {
          node {
            product_id
            original_prices
            prices
            local_image_name
            fields {
              full_name
              full_name_url
            }
          }
        }
      }
      images_small: allImageSharp {
        edges {
          node {
            fixed(height: 200, width: 200) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
          }
        }
      }
      gatsbySettings: allMongodbStoreGatsbySettings {
        edges {
          node {
            category
            key
            value
          }
        }
      }
    }
  `)

  // Create a lookup map for MongoDB products (product_id → { full_name, full_name_url })
  const productLookup = {}
  let miscSettings = {}
  data.gatsbySettings.edges.forEach(function (item, index) {
    if (item.node.category === "misc") {
      miscSettings[item.node.key] = item.node.value
    }
  })
  const PRODUCT_PATH = miscSettings["product_root_path"]
  data.allMongodbStoreProducts.edges.forEach(({ node }) => {
    productLookup[node.product_id] = {
      full_name: node.fields.full_name,
      full_name_url: node.fields.full_name_url,
      local_image_name: node.local_image_name,
      original_prices: node.original_prices,
      prices: node.prices,
    }
  })

  // Filter products by articleId
  const filteredProducts = data.allMysqlArticleProducts.nodes.filter(
    product => product.article_key === articleKey
  )

  if (filteredProducts.length === 0) {
    return <p>Inga produkter hittades för denna artikel.</p>
  }

  return (
    <div className="article-products-container">
      <h2 className="article-products-title">Rekommenderade produkter</h2>

      <div className="product-grid">
        {filteredProducts.map(product => {
          // Get product details from MongoDB lookup or fallback values
          const productDetails = productLookup[product.product_id] || {}
          const imageSrc = productDetails.local_image_name
          const productName =
            productDetails.full_name || `Produkt ${product.product_id}`
          let internalProductLink =
            PRODUCT_PATH + "/" + productDetails.full_name_url + "/"

          let price = Math.min(productDetails.prices)
          let original_price = Math.min(productDetails.original_prices)
          let discount = Math.round(
            (100.0 * (original_price - price)) / original_price
          )
          let discount_badge = <></>

          let price_div = (
            <div className="price">
              <p className="">{price + " kr"}</p>
            </div>
          )

          if (discount > 1) {
            discount_badge = <div className="discount-badge">-{discount}%</div>
            price_div = (
              <div className="price">
                <p className="discount_price">{price + " kr"}</p>
                <p className="original_price">{original_price + " kr"}</p>
              </div>
            )
          }

          let image = data.images_small.edges.find(n => {
            return n.node.fixed.src.includes(imageSrc)
          })
          if (!image) {
            console.log(
              "WARNING: Could not find image for " +
                productName +
                "(" +
                imageSrc +
                ")"
            )
            image = data.images_small.edges.find(n => {
              return n.node.fixed.src.includes("image_not_found.png")
            })
          }

          // Parse JSON strings safely
          let parsedPros = []
          let parsedCons = []
          try {
            parsedPros = JSON.parse(product.pros)
            parsedCons = JSON.parse(product.cons)
          } catch (error) {
            console.error("Error parsing pros/cons JSON:", error)
          }

          return (
            <Link to={internalProductLink}>
              <div key={product.product_id} className="product-card">
                {discount_badge}
                <div className="product-image-container">
                  <Img
                    fixed={image.node.fixed}
                    alt={productName}
                    title={productName}
                    className="product-image"
                  />
                </div>
                <div className="product-info">
                  <h3 className="product-title">{productName}</h3>
                  {price_div}
                  <p className="product-reason">{product.reason}</p>
                  <div className="product-pros-cons">
                    <div className="pros-box">
                      <strong>Fördelar:</strong>
                      <ul>
                        {Array.isArray(parsedPros) && parsedPros.length > 0 ? (
                          parsedPros.map((pro, index) => (
                            <li key={index}>{pro}</li>
                          ))
                        ) : (
                          <li>Inga fördelar angivna</li>
                        )}
                      </ul>
                    </div>
                    <div className="cons-box">
                      <strong>Nackdelar:</strong>
                      <ul>
                        {Array.isArray(parsedCons) && parsedCons.length > 0 ? (
                          parsedCons.map((con, index) => (
                            <li key={index}>{con}</li>
                          ))
                        ) : (
                          <li>Inga nackdelar angivna</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default ArticleProducts
